import { Link, useLocation, useNavigate } from "react-router-dom";

const MDNavBar = () =>  {

  const location = useLocation();
  const navigate = useNavigate();
  if(location.state.apiauthdata === "swapallowedpermit"){
    
  }else {
    navigate("/");
  }

    return(
        <>

<nav className="navbar navbar-expand-lg bg-light">
  <div className="container-fluid">
    <Link className="navbar-brand" to="/home" state={{apiauthdata : location.state.apiauthdata}}>
      AD Pharma Equipment Data Logger
    </Link>
    <button
      className="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span className="navbar-toggler-icon" />
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <Link className="nav-link" aria-current="page" to="/home" state={{apiauthdata : location.state.apiauthdata}}> 
            Temp & RH Data Logger
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/temp" state={{apiauthdata : location.state.apiauthdata}}>
           Temp Data Logger
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/list" state={{apiauthdata : location.state.apiauthdata}}>
           Report
           {/* 
           Sub Option: TEMP & RH Rreport
                        Temp Repo
                        
           */}
          </Link>
        </li>

        {/* <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Dropdown
          </a>
          <ul className="dropdown-menu">
            <li>
              <a className="dropdown-item" href="#">
                Action
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                Another action
              </a>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li>
              <a className="dropdown-item" href="#">
                Something else here
              </a>
            </li>
          </ul>
        </li> */}


        {/* <li className="nav-item">
          <a className="nav-link disabled">Disabled</a>
        </li> */}


      </ul>
      {/* <form className="d-flex" role="search">
        <input
          className="form-control me-2"
          type="search"
          placeholder="Search"
          aria-label="Search"
        />
        <button className="btn btn-outline-success" type="submit">
          Search
        </button>
      </form> */}
    </div>
  </div>
</nav>



        </>
    )
}
export default MDNavBar;