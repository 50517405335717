import { useState } from "react";
import MDNavBar from "./Components/MDNavBar";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { ServerUrl } from "./ServerString";


function App() {
const location = useLocation();
const navigate = useNavigate();

if(location.state.apiauthdata === "swapallowedpermit"){

}else {
  navigate("/");
}



  const [oldFromDate, newFromDate] = useState();
  const [oldToDate, newToDate] = useState();
  const [oldTimeInterval, newTimeInterval] = useState();
  const [oldTempInCel, newTempInCel] = useState();
  const [oldHumPer, newHumPer] = useState();
  const [oldTemShuff, newTemShuff] = useState();
  const [oldHumShuff, newHumShuff] = useState();
// Later On Added
  const [oldReportType, newReportType] = useState("Calibration");
  const [oldEqMake, newEqMake] = useState();
  const [oldPrepBy, newPrepBy] = useState();
  const [oldEqId, newEqId] = useState();
  const [oldEqName, newEqName]= useState();
  const [oldChanNo, newChanNo] = useState(8);
 const [oldAdate, newAdate] = useState();
const ObjData = {
  oldFromDate, oldToDate, oldTimeInterval, oldTempInCel, oldHumPer, oldTemShuff, oldHumShuff, oldReportType, oldEqMake, oldPrepBy, oldEqId, oldEqName, oldChanNo
}

console.log(ObjData);



  function ValidateTheData(oldFromDate, oldToDate, oldTimeInterval, oldTempInCel, oldHumPer, oldTemShuff, oldHumShuff){
      if(oldFromDate < oldToDate){
       
        if(isNaN(oldTimeInterval) || isNaN(oldHumPer) || isNaN(oldTempInCel) || 
        isNaN(oldTemShuff) || isNaN(oldHumShuff)
        ){
         
          return false;
        }else {
          
          return true;

        }

      }else{
        return false
      }
  }
 

const GenerateReportData = (oldFromDate, oldToDate, oldTimeInterval, oldTempInCel, oldHumPer, oldTemShuff, oldHumShuff) =>
{
        function getRandomFloat(min, max) {
          return parseFloat((Math.random() * (max - min) + min).toFixed(1));
        }
        
        // Set initial temperature and humidity values and range for shuffle
        // const initialTemp = [25.0, 25.5, 24.9, 26.0, 25.8, 24.5, 25.6, 24.8];
        // const initialHumidity = [50.0, 49.8, 51.2, 49.5, 50.5, 51.0, 50.3, 49.9];
        // const initialTemp = [parseFloat(oldTempInCel), parseFloat(oldTempInCel)+0.5, parseFloat(oldTempInCel)+0.9-1.0, parseFloat(oldTempInCel)+1.0, parseFloat(oldTempInCel)+0.8, parseFloat(oldTempInCel)+0.5-1.0, parseFloat(oldTempInCel)+0.6, parseFloat(oldTempInCel)+0.8-1.0];
        
        // const initialHumidity = [parseFloat(oldHumPer), parseFloat(oldHumPer)-1.0+0.8, parseFloat(oldHumPer)+1.2, parseFloat(oldHumPer)-1.0+0.5, parseFloat(oldHumPer)+0.5, parseFloat(oldHumPer)+1.0, parseFloat(oldHumPer)+0.3, parseFloat(oldHumPer)-0.1];

        const initialTemp = [parseFloat(oldTempInCel), parseFloat(oldTempInCel), parseFloat(oldTempInCel), parseFloat(oldTempInCel), parseFloat(oldTempInCel), parseFloat(oldTempInCel), parseFloat(oldTempInCel), parseFloat(oldTempInCel)];
        
        const initialHumidity = [parseFloat(oldHumPer), parseFloat(oldHumPer), parseFloat(oldHumPer), parseFloat(oldHumPer), parseFloat(oldHumPer), parseFloat(oldHumPer), parseFloat(oldHumPer), parseFloat(oldHumPer)];

        console.log(initialTemp);
        console.log(initialHumidity);
        // const tempRange = 0.5;
        const tempRange = parseFloat(oldTemShuff);

        // const humidityRange = 10.0;
        const humidityRange = parseFloat(oldHumShuff);
        
        // Get the start and end dates/times
        const startDateTime = new Date(oldFromDate);
        const endDateTime = new Date(oldToDate);
        const timeInterval = parseInt(oldTimeInterval); // in minutes
        
        // Create the CSV header
        let csvDataB = 'Date ,Time,Channel 1 Temperature (°C),Channel 1 Humidity (%),Channel 2 Temperature (°C),Channel 2 Humidity (%),Channel 3 Temperature (°C),Channel 3 Humidity (%),Channel 4 Temperature (°C),Channel 4 Humidity (%),Channel 5 Temperature (°C),Channel 5 Humidity (%),Channel 6 Temperature (°C),Channel 6 Humidity (%),Channel 7 Temperature (°C),Channel 7 Humidity (%),Channel 8 Temperature (°C),Channel 8 Humidity (%)\n';
        let csvData = '';
        
        // Loop through the time intervals and generate the random temperature and humidity values for each channel

        
        let currentDateTime = new Date(startDateTime.getTime());
        while (currentDateTime <= endDateTime) {
          let rowData = currentDateTime.toLocaleString("en-GB");
          for (let i = 0; i < 8; i++) {
            const temperature = getRandomFloat(initialTemp[i] - tempRange, initialTemp[i] + tempRange);
            const humidity = getRandomFloat(initialHumidity[i] - humidityRange, initialHumidity[i] + humidityRange);
            rowData += ',' + temperature.toFixed(1) + ',' + humidity.toFixed(1);
          }
          csvData += rowData + '\n';
          csvDataB +=rowData + '\n';
          currentDateTime.setMinutes(currentDateTime.getMinutes() + timeInterval);
        }
        
        // Create a new Blob object with the CSV data
        const csvBlob = new Blob([csvData], { type: 'text/csv' });
       





        const arrayTest = csvDataB.split("\n").map(data =>{
          return data.split(",");
        })
        
        const dataObj = arrayTest.filter(data=>  data[0] !== "" );


        // Pro Data

        const data = dataObj;

        const minMaxData = [];
        for (let i = 2; i < data[0].length; i += 2) {
          const channelData = data.slice(1).map(row => ({
            temperature: parseFloat(row[i]),
            humidity: parseFloat(row[i+1])
          }));
          const minMax = {
            channel: data[0][i],
            minTemp: Math.min(...channelData.map(d => d.temperature)),
            maxTemp: Math.max(...channelData.map(d => d.temperature)),
            minHumidity: Math.min(...channelData.map(d => d.humidity)),
            maxHumidity: Math.max(...channelData.map(d => d.humidity))
          };
          minMaxData.push(minMax);
        }

      // console.log(minMaxData);
        // End Pro Data






        const array = csvData.split("\n").map(data =>{
          return data.split(",");
        })
      
        return [array.filter(data=>  data[0] !== "" ), minMaxData];

        // navigate("showTab", {replace:false, state:{
        //   arrayOfData : array
        // }})

}





// Generate Data for 12 Rows


const GenerateReportDataTwelveRows = (oldFromDate, oldToDate, oldTimeInterval, oldTempInCel, oldHumPer, oldTemShuff, oldHumShuff) =>
{
        function getRandomFloat(min, max) {
          return parseFloat((Math.random() * (max - min) + min));
        }
        
        // Set initial temperature and humidity values and range for shuffle
        // const initialTemp = [25.0, 25.5, 24.9, 26.0, 25.8, 24.5, 25.6, 24.8];
        // const initialHumidity = [50.0, 49.8, 51.2, 49.5, 50.5, 51.0, 50.3, 49.9];
/*        const initialTemp = [parseFloat(oldTempInCel), parseFloat(oldTempInCel)+0.5, parseFloat(oldTempInCel)+0.9-1.0, parseFloat(oldTempInCel)+1.0, parseFloat(oldTempInCel)+0.8, parseFloat(oldTempInCel)+0.5-1.0, parseFloat(oldTempInCel)+0.6, parseFloat(oldTempInCel)+0.8-1.0,  parseFloat(oldTempInCel)+0.7-1.0,  parseFloat(oldTempInCel)+0.5-1.0,  parseFloat(oldTempInCel)+0.2-1.0,  parseFloat(oldTempInCel)+0.5-1.1];
        
        const initialHumidity = [parseFloat(oldHumPer), parseFloat(oldHumPer)-1.0+0.8, parseFloat(oldHumPer)+1.2, parseFloat(oldHumPer)-1.0+0.5, parseFloat(oldHumPer)+0.5, parseFloat(oldHumPer)+1.0, parseFloat(oldHumPer)+0.3, parseFloat(oldHumPer)-0.1, parseFloat(oldHumPer)-0.4, parseFloat(oldHumPer)-0.8, parseFloat(oldHumPer)-0.2, parseFloat(oldHumPer)-0.6];
*/
        const initialTemp = [parseFloat(oldTempInCel), parseFloat(oldTempInCel), parseFloat(oldTempInCel), parseFloat(oldTempInCel), parseFloat(oldTempInCel), parseFloat(oldTempInCel), parseFloat(oldTempInCel), parseFloat(oldTempInCel),  parseFloat(oldTempInCel),  parseFloat(oldTempInCel),  parseFloat(oldTempInCel),  parseFloat(oldTempInCel)];
        
        const initialHumidity = [parseFloat(oldHumPer), parseFloat(oldHumPer), parseFloat(oldHumPer), parseFloat(oldHumPer), parseFloat(oldHumPer), parseFloat(oldHumPer), parseFloat(oldHumPer), parseFloat(oldHumPer), parseFloat(oldHumPer), parseFloat(oldHumPer), parseFloat(oldHumPer), parseFloat(oldHumPer)];

        console.log(initialTemp);
        console.log(initialHumidity);
        // const tempRange = 0.5;
        const tempRange = parseFloat(oldTemShuff);

        // const humidityRange = 10.0;
        const humidityRange = parseFloat(oldHumShuff);
        
        // Get the start and end dates/times
        const startDateTime = new Date(oldFromDate);
        const endDateTime = new Date(oldToDate);
        const timeInterval = parseInt(oldTimeInterval); // in minutes
        
        // Create the CSV header
        let csvDataB = 'Date ,Time,Channel 1 Temperature (°C),Channel 1 Humidity (%),Channel 2 Temperature (°C),Channel 2 Humidity (%),Channel 3 Temperature (°C),Channel 3 Humidity (%),Channel 4 Temperature (°C),Channel 4 Humidity (%),Channel 5 Temperature (°C),Channel 5 Humidity (%),Channel 6 Temperature (°C),Channel 6 Humidity (%),Channel 7 Temperature (°C),Channel 7 Humidity (%),Channel 8 Temperature (°C),Channel 8 Humidity (%),Channel 9 Temperature (°C),Channel 9 Humidity (%),Channel 10 Temperature (°C),Channel 10 Humidity (%),Channel 11 Temperature (°C),Channel 11 Humidity (%),Channel 12 Temperature (°C),Channel 12 Humidity (%)\n';
        let csvData = '';
        
        // Loop through the time intervals and generate the random temperature and humidity values for each channel

        
        let currentDateTime = new Date(startDateTime.getTime());
        while (currentDateTime <= endDateTime) {
          let rowData = currentDateTime.toLocaleString("en-GB");
          for (let i = 0; i < 12; i++) {
            const temperature = getRandomFloat(initialTemp[i] - tempRange+Math.random(), initialTemp[i] + tempRange-Math.random());

            const humidity = getRandomFloat(initialHumidity[i] - humidityRange+Math.random(), initialHumidity[i] + humidityRange-Math.random());
            
            rowData += ',' + temperature.toFixed(1) + ',' + humidity.toFixed(1);
          }
          csvData += rowData + '\n';
          csvDataB +=rowData + '\n';
          currentDateTime.setMinutes(currentDateTime.getMinutes() + timeInterval);
        }
        
        // Create a new Blob object with the CSV data
        const csvBlob = new Blob([csvData], { type: 'text/csv' });
       





        const arrayTest = csvDataB.split("\n").map(data =>{
          return data.split(",");
        })
        
        const dataObj = arrayTest.filter(data=>  data[0] !== "" );


        // Pro Data

        const data = dataObj;

        const minMaxData = [];
        for (let i = 2; i < data[0].length; i += 2) {
          const channelData = data.slice(1).map(row => ({
            temperature: parseFloat(row[i]),
            humidity: parseFloat(row[i+1])
          }));
          const minMax = {
            channel: data[0][i],
            minTemp: Math.min(...channelData.map(d => d.temperature)),
            maxTemp: Math.max(...channelData.map(d => d.temperature)),
            minHumidity: Math.min(...channelData.map(d => d.humidity)),
            maxHumidity: Math.max(...channelData.map(d => d.humidity))
          };
          minMaxData.push(minMax);
        }

      // console.log(minMaxData);
        // End Pro Data






        const array = csvData.split("\n").map(data =>{
          return data.split(",");
        })
      
        return [array.filter(data=>  data[0] !== "" ), minMaxData];

        // navigate("showTab", {replace:false, state:{
        //   arrayOfData : array
        // }})

}

// Generate Data for 12 Rows







  const HandleFormSubmitData = (event) => { 
    event.preventDefault();
    if(Boolean(oldFromDate) && Boolean(oldToDate) && Boolean(oldTimeInterval) && Boolean(oldTempInCel) && Boolean(oldHumPer) && Boolean(oldHumShuff) && Boolean(oldTemShuff) && Boolean(oldReportType) && Boolean(oldEqMake) && Boolean(oldPrepBy) && Boolean(oldEqId) && Boolean(oldEqName) && Boolean(oldChanNo)
    ){
      if(ValidateTheData(oldFromDate, oldToDate, oldTimeInterval, oldTempInCel, oldHumPer, oldTemShuff, oldHumShuff) === true){
      const SwapRepoDataArray = GenerateReportDataTwelveRows(oldFromDate, oldToDate, oldTimeInterval, oldTempInCel, oldHumPer, oldTemShuff, oldHumShuff);

      console.log(SwapRepoDataArray);

       const formdata = new FormData();
       formdata.append("apikey", "swapnilpro");
       formdata.append("eqmake", oldEqMake);
       formdata.append("eqname", oldEqName);
       formdata.append("afromdate", oldFromDate);
       formdata.append("atodate", oldToDate);
       formdata.append("temp", oldTempInCel);
       formdata.append("humidity", oldHumPer);
       formdata.append("tempshuff", oldTemShuff);
       formdata.append("humshuff", oldHumShuff);
       formdata.append("atype", oldReportType);
       formdata.append("noofchannel", oldChanNo);
       formdata.append("prepby", oldPrepBy);
       formdata.append("eqid", oldEqId);
       formdata.append("ave", JSON.stringify(SwapRepoDataArray[1]));
       formdata.append("agendata", JSON.stringify(SwapRepoDataArray[0]));
       formdata.append("atimeint", oldTimeInterval);

formdata.append("adate", oldAdate);
        axios.post(ServerUrl+"rdata/PostRepoData.php", formdata).then(response => {
          console.log(response.data);
          alert(response.data);
          window.location.reload();
        })





      }else {
        alert("Error Enter Correct Details");
      }
    }
    else{
      alert("Select All Field");
    }
  }


  return (
    <>
    <MDNavBar />
    <form onSubmit={HandleFormSubmitData}>
    <div className="container"> 
<div className="container text-center">
  <div className="row">
    <div className="col">
        
          <br/>
     
             <input type="text" onChange={(e)=>{newReportType(e.target.value);}} className="form form-control" placeholder="Enter A Report Type" />
         
              <input type="text" onChange={(e)=>{ newEqMake(e.target.value); }} className="form form-control" placeholder="Enter Equipment Make"  />
              <br/>
              
              <input type="text" onChange={(e)=>{ newPrepBy(e.target.value); }} className="form form-control" placeholder="Enter Prepared By" />
              <br/>
                          
              <input type="text" onChange={(e)=>{ newEqId(e.target.value); }} className="form form-control" placeholder="Enter Equipment Id" />
        
              
              <input type="text" onChange={(e)=>{ newEqName(e.target.value); }} className="form form-control" placeholder="Enter Equipment Name" />
          


              <label>Select No of Channel</label>
              <select 
              value={oldChanNo}
              onChange={(e)=> { 
                newChanNo(e.target.value);
              }} className="form form-control select" placeholder="Select No of Channel">
                <option value={1}>1 Channel</option>  
                <option value={4}>4 Channel</option>
                <option value={8}>8 Channel</option>
                <option value={9}>9 Channel</option>
                <option value={10}>10 Channel</option>
                <option value={11}>11 Channel</option>
                <option value={12}>12 Channel</option>
              </select>

              <label>Select Date and Time of Report</label>
              <input type="datetime-local" step="1" onChange={(e)=>{ newAdate(e.target.value); }} className="form form-control" />


              <label>Select From Date and Time</label>
              <input type="datetime-local" step="1" onChange={(e)=>{ newFromDate(e.target.value); }} className="form form-control" />
            
    </div>
    <div className="col">
          

            
              <label>Select To Date and Time</label>
              <input type="datetime-local" step="1" onChange={(e) => { newToDate(e.target.value); }} className="form form-control" />
              <br/>
              
              <input type="text" className="form form-control" onChange={(e)=>{
                newTimeInterval(e.target.value);
              }} placeholder="Enter Interval on minutes" />
              <br />
           
              <input type="text" className="form form-control" onChange={(e)=>{
                newTempInCel(e.target.value);
              }} placeholder="Enter Temperature °C" />
              <br />
      
              <input type="text"
              onChange={(e)=>{
                newHumPer(e.target.value);
              }}
              className="form form-control" placeholder="Enter Humidity in %" />
              <br />
            
              <input type="text" onChange={(e)=>{
                newTemShuff(e.target.value);
              }} className="form form-control" placeholder="Enter Temperature to Shuffle" />
              <br />
              
              <input type="text"
              onChange={(e)=>{
                newHumShuff(e.target.value);
              }}
              className="form form-control" placeholder="Enter Humidity to Shuffle" />
              <br />

              <button type="submit" className="btn btn-primary">Generate Data</button>&nbsp;&nbsp;
              <button type="reset" className="btn btn-danger">Clear Data</button>
              <br/>
              <hr/>


      
    </div>


  </div>
  </div>
</div>
</form>



   


    </>
  );
}

export default App;