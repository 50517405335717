import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './App';
import ListDataRepo from './Components/ListDataRepo';
import ShowData from './Components/ShowData';
import LoginPage from './LoginPage';
import TempData from './TempData';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  <Routes>
    <Route path='/' element={<LoginPage />} />
    <Route path='/home' element={<App />} />

    <Route path='/showTab' element={<ShowData />} />
    <Route path='/list' element={<ListDataRepo />} />
    <Route path='/temp' element={<TempData />} />
    <Route path='*' element={<h1>Error 404 !</h1>} />
  </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

