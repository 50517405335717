import { useLocation } from "react-router-dom";
// import html2pdf  from "html2pdf.js";
import axios from "axios";
const ShowData = () => { 
    const location = useLocation();
    const ArrayDataLog = location.state.arrayOfData;
    // console.log(ArrayDataLog);
    const filterredArraySwap = ArrayDataLog.filter(data => data[0] !== "");
    console.log(filterredArraySwap)
    console.log(JSON.stringify(filterredArraySwap));
    // const formdata = new FormData();
    // formdata.append('arrayOfData', JSON.stringify(filterredArraySwap));
    // axios.post("http://localhost/rdata/ECPdf.php", formdata).then(response => { 
    //   console.log("done");
    // })
    





    return(<>



<div id="adreport" className="container-fluid">

<div style={{"textAlign":"center"}}>  
<h5 style={{"color":"green"}} >AD Pharma Equipment Pvt. Ltd.-Nashik</h5>
<label >Validation</label> 
</div>

<>
  <style
    type="text/css"
    dangerouslySetInnerHTML={{
      __html:
        "\n.tg  {border-collapse:collapse;border-spacing:0;margin:0px auto;}\n.tg td{border-color:black;border-style:solid;border-width:0px;font-family:Arial, sans-serif;font-size:14px;\n  overflow:hidden;padding:0px 19px;word-break:normal;}\n.tg th{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;\n  font-weight:normal;overflow:hidden;padding:0px 19px;word-break:normal;}\n.tg .tg-eshq{border-color:#000000;font-size:10px;text-align:left;vertical-align:top}\n@media screen and (max-width: 767px) {.tg {width: auto !important;}.tg col {width: auto !important;}.tg-wrap {overflow-x: auto;-webkit-overflow-scrolling: touch;margin: auto 0px;}}"
    }}
  />
  <div className="tg-wrap">
    {/* 699 */}
    <table className="tg" style={{ tableLayout: "fixed", width: 1200 }}>
      <colgroup>
        <col style={{ width: 100 }} />
        <col style={{ width: 318 }} />
        <col style={{ width: 100 }} />
        <col style={{ width: 126 }} />
      </colgroup>
      <tbody>
        <tr>
          <td className="tg-eshq">Equipment Make :</td>
          <td className="tg-eshq">Mack Pharmatech</td>
          <td className="tg-eshq">Temp Limits :</td>
          <td className="tg-eshq">30.0 ± 2.0 °C</td>
        </tr>
        <tr>
          <td className="tg-eshq">Equipment Name :</td>
          <td className="tg-eshq">Walk In Stability Chamber</td>
          <td className="tg-eshq">Humidity Limits :</td>
          <td className="tg-eshq">65.0 ± 5.0 %RH</td>
        </tr>
        <tr>
          <td className="tg-eshq">From Date &amp; Time :</td>
          <td className="tg-eshq">12/07/2022 &amp; 11:30</td>
          <td className="tg-eshq">Prepared By :</td>
          <td className="tg-eshq">ADMIN</td>
        </tr>
        <tr>
          <td className="tg-eshq">To Date &amp; Time :</td>
          <td className="tg-eshq">13/07/2022 &amp; 11:30</td>
          <td className="tg-eshq">Print Date &amp; Time :</td>
          <td className="tg-eshq">13/07/2022 &amp; 11:43</td>
        </tr>
      </tbody>
    </table>
  </div>
</>

{/* main Table */}
<>
  <style
    type="text/css"
    dangerouslySetInnerHTML={{
      __html:
        "\n.tga  {border-collapse:collapse;border-spacing:0;margin:0px auto;}\n.tga td{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;\n  overflow:hidden;padding:7px 10px;word-break:normal;}\n.tga th{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;\n  font-weight:normal;overflow:hidden;padding:7px 10px;word-break:normal;}\n.tga .tg-7jts{border-color:inherit;font-size:10px;text-align:center;vertical-align:top}\n.tga .tg-i4oe{border-color:inherit;color:#3531ff;font-size:14px;text-align:left;vertical-align:top}\n.tga .tg-pt68{border-color:inherit;color:#963400;font-size:15px;text-align:left;vertical-align:top}\n.tga .tg-drr0{border-color:inherit;color:#6200c9;font-size:10px;text-align:left;vertical-align:top}\n.tga .tg-wo5q{border-color:inherit;color:#54f5a3;font-size:10px;text-align:left;vertical-align:top}\n@media screen and (max-width: 767px) {.tga {width: auto !important;}.tga col {width: auto !important;}.tg-wrap {overflow-x: auto;-webkit-overflow-scrolling: touch;margin: auto 0px;}}"
    }}
  />
  <div className="tg-wrap">
    <table className="tga" style={{ tableLayout: "fixed", width: 1200 }}>
      <tbody>
        <tr>
          <td className="tg-7jts" colSpan={18}>
            EQUIPMENT ID : 12/23/12A
          </td>
        </tr>
        <tr>
          <td className="tg-i4oe" rowSpan={2}>
            Date
          </td>
          <td className="tg-i4oe" rowSpan={2}>
            Time
          </td>
          <td className="tg-pt68" colSpan={2}>
            Channel 1
          </td>
          <td className="tg-pt68" colSpan={2}>
            Channel 2
          </td>
          <td className="tg-pt68" colSpan={2}>
            Channel 3
          </td>
          <td className="tg-pt68" colSpan={2}>
            Channel 4
          </td>
          <td className="tg-pt68" colSpan={2}>
            Channel 5
          </td>
          <td className="tg-pt68" colSpan={2}>
            Channel 6
          </td>
          <td className="tg-pt68" colSpan={2}>
            Channel 7
          </td>
          <td className="tg-pt68" colSpan={2}>
            Channel 8
          </td>
        </tr>
        <tr>
          <td className="tg-drr0">
            T1
            <br />
            °C
          </td>
          <td className="tg-wo5q">
            H1
            <br />%
          </td>
          <td className="tg-drr0">
            T2
            <br />
            °C
          </td>
          <td className="tg-wo5q">
            H2
            <br />%
          </td>
          <td className="tg-drr0">
            T3
            <br />
            °C
          </td>
          <td className="tg-wo5q">
            H3
            <br />%
          </td>
          <td className="tg-drr0">
            T4
            <br />
            °C
          </td>
          <td className="tg-wo5q">
            H4
            <br />%
          </td>
          <td className="tg-drr0">
            T5
            <br />
            °C
          </td>
          <td className="tg-wo5q">
            H5
            <br />%
          </td>
          <td className="tg-drr0">
            T6
            <br />
            °C
          </td>
          <td className="tg-wo5q">
            H6
            <br />%
          </td>
          <td className="tg-drr0">
            T7
            <br />
            °C
          </td>
          <td className="tg-wo5q">
            H7
            <br />%
          </td>
          <td className="tg-drr0">
            T8
            <br />
            °C
          </td>
          <td className="tg-wo5q">
            H8
            <br />%
          </td>
        </tr>

        {ArrayDataLog.filter(Swapdata =>  Swapdata[0] !== "" ).map(data =>{
            const localTImestringData = data[1];
            const timeString12hr = new Date(data[0]+localTImestringData)
  .toLocaleTimeString('en-US',
    {timeZone:'UTC',hour12:false,hour:'numeric',minute:'numeric'}
  );
            return <tr>
            <td className="tg-i4oe"> {data[0]} </td>
            <td className="tg-i4oe"> {timeString12hr} </td>
            <td className="tg-drr0"> {parseFloat(data[2]).toFixed(1)} </td>
            <td className="tg-wo5q"> {parseFloat(data[3]).toFixed(1)} </td>
            <td className="tg-drr0"> {parseFloat(data[4]).toFixed(1)} </td>
            <td className="tg-wo5q"> {parseFloat(data[5]).toFixed(1)} </td>
            <td className="tg-drr0"> {parseFloat(data[6]).toFixed(1)} </td>
            <td className="tg-wo5q"> {parseFloat(data[7]).toFixed(1)} </td>
            <td className="tg-drr0"> {parseFloat(data[8]).toFixed(1)} </td>
            <td className="tg-wo5q"> {parseFloat(data[9]).toFixed(1)} </td>
            <td className="tg-drr0"> {parseFloat(data[10]).toFixed(1)} </td>
            <td className="tg-wo5q"> {parseFloat(data[11]).toFixed(1)} </td>
            <td className="tg-drr0"> {parseFloat(data[12]).toFixed(1)} </td>
            <td className="tg-wo5q"> {parseFloat(data[13]).toFixed(1)} </td>
            <td className="tg-drr0"> {parseFloat(data[14]).toFixed(1)} </td>
            <td className="tg-wo5q"> {parseFloat(data[15]).toFixed(1)} </td>
            <td className="tg-drr0"> {parseFloat(data[16]).toFixed(1)} </td>
            <td className="tg-wo5q"> {parseFloat(data[17]).toFixed(1)} </td>
        
          </tr>
        })}
        
        {/* <tr>
          <td className="tg-i4oe" />
          <td className="tg-i4oe" />
          <td className="tg-drr0" />
          <td className="tg-wo5q" />
          <td className="tg-drr0" />
          <td className="tg-wo5q" />
          <td className="tg-drr0" />
          <td className="tg-wo5q" />
          <td className="tg-drr0" />
          <td className="tg-wo5q" />
          <td className="tg-drr0" />
          <td className="tg-wo5q" />
          <td className="tg-drr0" />
          <td className="tg-wo5q" />
          <td className="tg-drr0" />
          <td className="tg-wo5q" />
          <td className="tg-drr0" />
          <td className="tg-wo5q" />
        </tr>
        <tr>
          <td className="tg-i4oe" />
          <td className="tg-i4oe" />
          <td className="tg-drr0" />
          <td className="tg-wo5q" />
          <td className="tg-drr0" />
          <td className="tg-wo5q" />
          <td className="tg-drr0" />
          <td className="tg-wo5q" />
          <td className="tg-drr0" />
          <td className="tg-wo5q" />
          <td className="tg-drr0" />
          <td className="tg-wo5q" />
          <td className="tg-drr0" />
          <td className="tg-wo5q" />
          <td className="tg-drr0" />
          <td className="tg-wo5q" />
          <td className="tg-drr0" />
          <td className="tg-wo5q" />
        </tr>
        <tr>
          <td className="tg-i4oe" />
          <td className="tg-i4oe" />
          <td className="tg-drr0" />
          <td className="tg-wo5q" />
          <td className="tg-drr0" />
          <td className="tg-wo5q" />
          <td className="tg-drr0" />
          <td className="tg-wo5q" />
          <td className="tg-drr0" />
          <td className="tg-wo5q" />
          <td className="tg-drr0" />
          <td className="tg-wo5q" />
          <td className="tg-drr0" />
          <td className="tg-wo5q" />
          <td className="tg-drr0" />
          <td className="tg-wo5q" />
          <td className="tg-drr0" />
          <td className="tg-wo5q" />
        </tr>
        <tr>
          <td className="tg-i4oe" />
          <td className="tg-i4oe" />
          <td className="tg-drr0" />
          <td className="tg-wo5q" />
          <td className="tg-drr0" />
          <td className="tg-wo5q" />
          <td className="tg-drr0" />
          <td className="tg-wo5q" />
          <td className="tg-drr0" />
          <td className="tg-wo5q" />
          <td className="tg-drr0" />
          <td className="tg-wo5q" />
          <td className="tg-drr0" />
          <td className="tg-wo5q" />
          <td className="tg-drr0" />
          <td className="tg-wo5q" />
          <td className="tg-drr0" />
          <td className="tg-wo5q" />
        </tr>
        <tr>
          <td className="tg-i4oe" />
          <td className="tg-i4oe" />
          <td className="tg-drr0" />
          <td className="tg-wo5q" />
          <td className="tg-drr0" />
          <td className="tg-wo5q" />
          <td className="tg-drr0" />
          <td className="tg-wo5q" />
          <td className="tg-drr0" />
          <td className="tg-wo5q" />
          <td className="tg-drr0" />
          <td className="tg-wo5q" />
          <td className="tg-drr0" />
          <td className="tg-wo5q" />
          <td className="tg-drr0" />
          <td className="tg-wo5q" />
          <td className="tg-drr0" />
          <td className="tg-wo5q" />
        </tr> */}
      </tbody>
    </table>
  </div>
</>

</div>









    </>)
}

export default ShowData;