import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./LoginPage.css";
import { ServerUrl } from "./ServerString";
import usericon from "./usericon.png";
const LoginPage = () => { 
    const navigate = useNavigate();
    const [oldusername, newusername] = useState();
    const [oldpassword, newpassword] = useState();
    

    const HandleSubmit = (event) => { 
        event.preventDefault();
        if(Boolean(oldpassword) && Boolean(oldusername)){
            const formdata = new FormData();
            formdata.append("username", oldusername);
            formdata.append("password", oldpassword);
            axios.post(ServerUrl+"rdata/AuthData.php", formdata).then(response => {
                if(response.data === "done"){
                    navigate("home", { state: { apiauthdata :  "swapallowedpermit" } });

                }else {
                    alert("Invalid Credentials");
                }
            })
        }
        else{
            alert("All Fields Required");
        }
    }
    
    
    return <>
    


    <>
  <link
    href="//maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"
    rel="stylesheet"
    id="bootstrap-css"
  />
  <div className="wrapper fadeInDown">
    <div id="formContent">
      {/* Tabs Titles */}
      {/* Icon */}
      <div className="fadeIn first">
        <img
          src={usericon}
          id="icon"
          alt="User Icon"
          style={{"height":100, width : 100}}
        />
      </div>
      {/* Login Form */}
      <form onSubmit={HandleSubmit}>
        <input
          type="text"
          id="login"
          className="fadeIn second"
          name="login"
          placeholder="login"
          onChange={(e) => { 
            newusername(e.target.value);
          }}

        />
        <input
          type="password"
          id="password"
          className="fadeIn third"
          name="login"
          placeholder="password"
          onChange={(e)=>{ newpassword(e.target.value); }}
        />
        <input type="submit" className="fadeIn fourth" defaultValue="Log In" />
      </form>
      {/* Remind Passowrd */}
      {/* <div id="formFooter">
        <a className="underlineHover" href="#">
          Forgot Password?
        </a>
      </div> */}
    </div>
  </div>
</>

    
    </>;
}

export default LoginPage;