import axios from "axios";
import { useEffect, useState } from "react";
import { ServerUrl } from "../ServerString";
import MDNavBar from "./MDNavBar";

const ListDataRepo = () => { 
    const [oldDataTab, newDataTab] = useState([]);
    const [oldTempDataTab, newTempDataTab] = useState([]);

const DataGetter = () => {
  const formdata = new FormData();
  formdata.append("apikey", "swapnilpro");
  axios.post(ServerUrl+"rdata/GetList.php", formdata).then(
      response => { 
          newDataTab(response.data);
         
      }
  )
    }

    useEffect(()=>{
      DataGetter();
    },[]);

     
    const DataGetterTemp = () => {
      const formdata = new FormData();
      formdata.append("apikey", "swapnilpro");
      axios.post(ServerUrl+"rdata/temp/GetList.php", formdata).then(
          response => { 
            newTempDataTab(response.data);
             
          }
      )
    }

    useEffect(()=>{
      DataGetterTemp();
  },[]);
    return <>
    <MDNavBar />
<div className="container"> 
<div className="container text-center">
  <div className="row">
    <div className="col">
      {/* Table One */}
      <h3>Temperature and RH Reports</h3>
      <table className="table table-dark">
  <thead>
    <tr>
      <th scope="col">SNo</th>
      <th scope="col">A Make Name</th>
      <th scope="col">Machine Name</th>
      <th scope="col">No of Channel</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
    {oldDataTab.map(data => { 
      let AComp = <></>;
      switch (parseInt(data.noofchannel)) {
        case 1:
          AComp= <form action={ServerUrl+"rdata/ECPdfOne.php"} method="post">
                <input type="hidden" name="sno" value={data.sno} />
                <input type="text" placeholder="Temp Shuff" name="temp"/>
                <input type="text" placeholder="RH Shuff" name="rh"/>
                <button type="submit" className="btn btn-success">Print</button>
                <button className="btn btn-danger" onClick={(e)=>{
                  e.preventDefault();
                  const formdata = new FormData();
                  formdata.append("apikey", "swapnilpro");
                  formdata.append("sno", data.sno);
                  axios.post(ServerUrl+"rdata/OvDel.php", formdata).then(reponse => {
                    console.log(reponse.data);
                    alert(reponse.data);
                    DataGetter();
                  })
                }} >Delete</button>
           </form>;
          break;
        case 4:
            AComp= <form action={ServerUrl+"rdata/ECPdfFour.php"} method="post">
                  <input type="hidden" name="sno" value={data.sno} />
                  <input type="text" placeholder="Temp Shuff" name="temp"/>
                <input type="text" placeholder="RH Shuff" name="rh"/>
                  <button type="submit" className="btn btn-success">Print</button>
                  <button className="btn btn-danger" onClick={(e)=>{
                    e.preventDefault();
                  const formdata = new FormData();
                  formdata.append("apikey", "swapnilpro");
                  formdata.append("sno", data.sno);
                  axios.post(ServerUrl+"rdata/OvDel.php", formdata).then(reponse => {
                    console.log(reponse.data);
                    alert(reponse.data);DataGetter();
                  })
                }} >Delete</button>
             </form>;
            break;
        case 8:
          AComp= <form action={ServerUrl+"rdata/ECPdf.php"} method="post">
                <input type="hidden" name="sno" value={data.sno} />
                <input type="text" placeholder="Temp Shuff" name="temp"/>
                <input type="text" placeholder="RH Shuff" name="rh"/>
                <button type="submit" className="btn btn-success">Print</button>
                <button className="btn btn-danger" onClick={(e)=>{
                  e.preventDefault();
                  const formdata = new FormData();
                  formdata.append("apikey", "swapnilpro");
                  formdata.append("sno", data.sno);
                  axios.post(ServerUrl+"rdata/OvDel.php", formdata).then(reponse => {
                    console.log(reponse.data);
                    alert(reponse.data);DataGetter();
                  })
                }} >Delete</button>
           </form>;
          break;
        case 9:
          AComp= <form action={ServerUrl+"rdata/ECPdfNine.php"} method="post">
              <input type="hidden" name="sno" value={data.sno} />
              <input type="text" placeholder="Temp Shuff" name="temp"/>
                <input type="text" placeholder="RH Shuff" name="rh"/>
              <button type="submit" className="btn btn-success">Print</button>
              <button className="btn btn-danger" onClick={(e)=>{
                e.preventDefault();
                  const formdata = new FormData();
                  formdata.append("apikey", "swapnilpro");
                  formdata.append("sno", data.sno);
                  axios.post(ServerUrl+"rdata/OvDel.php", formdata).then(reponse => {
                    console.log(reponse.data);
                    alert(reponse.data);DataGetter();
                  })
                }} >Delete</button>
           </form>;
          break;
        
        case 10:
          AComp= <form action={ServerUrl+"rdata/ECPdfTen.php"} method="post">
          <input type="hidden" name="sno" value={data.sno} />
          <input type="text" placeholder="Temp Shuff" name="temp"/>
                <input type="text" placeholder="RH Shuff" name="rh"/>
          <button type="submit" className="btn btn-success">Print</button>&nbsp;
          <button type="button" className="btn btn-danger" onClick={(e)=>{
            e.preventDefault();
                  const formdata = new FormData();
                  formdata.append("apikey", "swapnilpro");
                  formdata.append("sno", data.sno);
                  axios.post(ServerUrl+"rdata/OvDel.php", formdata).then(reponse => {
                    console.log(reponse.data);
                    alert(reponse.data);DataGetter();
                  })
                }} >Delete</button>
       </form>;
          break;

        case 11:
          AComp= <form action={ServerUrl+"rdata/ECPdfEle.php"} method="post">
          <input type="hidden" name="sno" value={data.sno} />
          <input type="text" placeholder="Temp Shuff" name="temp"/>
                <input type="text" placeholder="RH Shuff" name="rh"/>
          <button type="submit" className="btn btn-success">Print</button>
          <button className="btn btn-danger" onClick={(e)=>{
            e.preventDefault();
                  const formdata = new FormData();
                  formdata.append("apikey", "swapnilpro");
                  formdata.append("sno", data.sno);
                  axios.post(ServerUrl+"rdata/OvDel.php", formdata).then(reponse => {
                    console.log(reponse.data);
                    alert(reponse.data);DataGetter();
                  })
                }} >Delete</button>
       </form>;
          break;

        case 12:
          AComp= <form action={ServerUrl+"rdata/ECPdfTwe.php"} method="post">
          <input type="hidden" name="sno" value={data.sno} />
          <input type="text" placeholder="Temp Shuff" name="temp"/>
                <input type="text" placeholder="RH Shuff" name="rh"/>
          <button type="submit" className="btn btn-success">Print</button>
          <button className="btn btn-danger" onClick={(e)=>{
            e.preventDefault();
                  const formdata = new FormData();
                  formdata.append("apikey", "swapnilpro");
                  formdata.append("sno", data.sno);
                  axios.post(ServerUrl+"rdata/OvDel.php", formdata).then(reponse => {
                   
                    alert(reponse.data);DataGetter();
                    
                  })
                }} >Delete</button>
       </form>;
          break;
        default:
          break;
      }

        return <tr>
        <th scope="row">{data.sno}</th>
        <td>{data.eqmake}</td>
        <td>{data.eqname}</td>
        <td>{data.noofchannel}</td>

        <td>
          
            {AComp}
            
        </td>
      </tr>
    }).reverse()}
    

  </tbody>
</table>


    </div>
    <div className="col">
      {/* Table Second of Temperature */}
      <h3>Temperature Reports</h3>
      
      <table className="table table-dark">
  <thead>
    <tr>
      <th scope="col">SNo</th>
      <th scope="col">A Make Name</th>
      <th scope="col">Machine Name</th>
      <th scope="col">No of Channel</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
    {oldTempDataTab.map(data => { 
      let AComp = <></>;
      switch (parseInt(data.noofchannel)) {
        case 1:
          AComp= <form action={ServerUrl+"rdata/temp/ECPdfOne.php"} method="post">
                <input type="hidden" name="sno" value={data.sno} />
                <input type="text" placeholder="Temp Shuff" name="temp"/>
                <button type="submit" className="btn btn-success">Print</button>
                <button className="btn btn-danger" onClick={(e)=>{
            e.preventDefault();
                  const formdata = new FormData();
                  formdata.append("apikey", "swapnilpro");
                  formdata.append("sno", data.sno);
                  axios.post(ServerUrl+"rdata/temp/OvDel.php", formdata).then(reponse => {
                   
                    alert(reponse.data);DataGetterTemp();
                    
                  })
                }} >Delete</button>
           </form>;
          break;
        case 4:
            AComp= <form action={ServerUrl+"rdata/temp/ECPdfFour.php"} method="post">
                  <input type="hidden" name="sno" value={data.sno} />
                  <input type="text" placeholder="Temp Shuff" name="temp" />
                  <button type="submit" className="btn btn-success">Print</button>
                  <button className="btn btn-danger" onClick={(e)=>{
            e.preventDefault();
                  const formdata = new FormData();
                  formdata.append("apikey", "swapnilpro");
                  formdata.append("sno", data.sno);
                  axios.post(ServerUrl+"rdata/temp/OvDel.php", formdata).then(reponse => {
                   
                    alert(reponse.data);DataGetterTemp();
                    
                  })
                }} >Delete</button>
             </form>;
            break;
        case 8:
          AComp= <form action={ServerUrl+"rdata/temp/ECPdf.php"} method="post">
                <input type="hidden" name="sno" value={data.sno} />
                <input type="text" placeholder="Temp Shuff" name="temp" />
                <button type="submit" className="btn btn-success">Print</button>
                <button className="btn btn-danger" onClick={(e)=>{
            e.preventDefault();
                  const formdata = new FormData();
                  formdata.append("apikey", "swapnilpro");
                  formdata.append("sno", data.sno);
                  axios.post(ServerUrl+"rdata/temp/OvDel.php", formdata).then(reponse => {
                   
                    alert(reponse.data);DataGetterTemp();
                    
                  })
                }} >Delete</button>
           </form>;
          break;
        case 9:
          AComp= <form action={ServerUrl+"rdata/temp/ECPdfNine.php"} method="post">
              <input type="hidden" name="sno" value={data.sno} />
              <input type="text" placeholder="Temp Shuff" name="temp"/>
              <button type="submit" className="btn btn-success">Print</button>
              <button className="btn btn-danger" onClick={(e)=>{
            e.preventDefault();
                  const formdata = new FormData();
                  formdata.append("apikey", "swapnilpro");
                  formdata.append("sno", data.sno);
                  axios.post(ServerUrl+"rdata/temp/OvDel.php", formdata).then(reponse => {
                   
                    alert(reponse.data);DataGetterTemp();
                    
                  })
                }} >Delete</button>
           </form>;
          break;
        
        case 10:
          AComp= <form action={ServerUrl+"rdata/temp/ECPdfTen.php"} method="post">
          <input type="hidden" name="sno" value={data.sno} />
          <input type="text" placeholder="Temp Shuff" name="temp"  />
          <button type="submit" className="btn btn-success">Print</button>
          <button className="btn btn-danger" onClick={(e)=>{
            e.preventDefault();
                  const formdata = new FormData();
                  formdata.append("apikey", "swapnilpro");
                  formdata.append("sno", data.sno);
                  axios.post(ServerUrl+"rdata/temp/OvDel.php", formdata).then(reponse => {
                   
                    alert(reponse.data);DataGetterTemp();
                    
                  })
                }} >Delete</button>
       </form>;
          break;

        case 11:
          AComp= <form action={ServerUrl+"rdata/temp/ECPdfEle.php"} method="post">
          <input type="hidden" name="sno" value={data.sno} />
          <input type="text" placeholder="Temp Shuff" name="temp"  />
          <button type="submit" className="btn btn-success">Print</button>
          <button className="btn btn-danger" onClick={(e)=>{
            e.preventDefault();
                  const formdata = new FormData();
                  formdata.append("apikey", "swapnilpro");
                  formdata.append("sno", data.sno);
                  axios.post(ServerUrl+"rdata/temp/OvDel.php", formdata).then(reponse => {
                   
                    alert(reponse.data);DataGetterTemp();
                    
                  })
                }} >Delete</button>
       </form>;
          break;

        case 12:
          AComp= <form action={ServerUrl+"rdata/temp/ECPdfTwe.php"} method="post">
          <input type="hidden" name="sno" value={data.sno} />
          <input type="text" placeholder="Temp Shuff" name="temp" />
          <button type="submit" className="btn btn-success">Print</button>
          <button className="btn btn-danger" onClick={(e)=>{
            e.preventDefault();
                  const formdata = new FormData();
                  formdata.append("apikey", "swapnilpro");
                  formdata.append("sno", data.sno);
                  axios.post(ServerUrl+"rdata/temp/OvDel.php", formdata).then(reponse => {
                   
                    alert(reponse.data);DataGetterTemp();
                    
                  })
                }} >Delete</button>
       </form>;
          break;
        default:
          break;
      }

        return <tr>
        <th scope="row">{data.sno}</th>
        <td>{data.eqmake}</td>
        <td>{data.eqname}</td>
        <td>{data.noofchannel}</td>

        <td>
          
            {AComp}
          
        </td>
      </tr>
    }).reverse()}
    

  </tbody>
</table>

    </div>
  </div>
</div>

</div>
    </>
}

export default ListDataRepo;